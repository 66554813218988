

























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUott1721M6_Task25',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {img: '/img/assignments/uottawa_task25_1.png', value: 'a'},
        {img: '/img/assignments/uottawa_task25_2.png', value: 'oneDeuterium'},
        {img: '/img/assignments/uottawa_task25_3.png', value: 'wrongDeuterium'},
        {img: '/img/assignments/uottawa_task25_4.png', value: 'noHydrogen'},
      ],
    };
  },
};
